<template>
    <v-toolbar flat color="white">
        <v-spacer></v-spacer>
        <v-dialog v-model="show" max-width="500px">
            <template v-slot:activator="{ on }">
                <v-btn outlined color="gray" @click="openFilterDialog()" dark class="mb-2 mr-3">
                    <v-icon color="neuter">mdi-filter-variant</v-icon>
                    {{$t('general.buttons.filter')}}
                </v-btn>
            </template>
            <v-card :light="true" color="white">
                <v-card-title>
                    <span class="headline">{{$t('general.titles.filter')}}</span>
                    <v-icon class="ml-1" style="font-size: 20px; color:#232323 !important ">mdi-alert-circle</v-icon>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <div  v-for="filter in fieldsFilters"
                                  :key="filter.text">
                                <v-select flat hide-details small-chips multiple clearable :items="columnValueList(filter.value)" v-model="filters[filter.value]">

                                </v-select>
                            </div>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn outlined color="gray" @click="closeFilterDialog">
                        {{$t('general.buttons.cancel')}}
                    </v-btn>
                    <v-btn outlined color="primary">
                        {{$t('general.buttons.save')}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-toolbar>
</template>

<script>
    export default {
        name: "ModalFilters",
        props: [
            'filters',
            'fieldsFilters',
            'filterData'
        ],
        data: () => ({
            show: false
        }),
        methods: {
            openFilterDialog() {
                this.show = true;
            },
            closeFilterDialog() {
                this.show = false;
            },
            columnValueList(val) {
                return this.filterData.map(d => d[val])
            },
        },
        computed:{

        }
    }
</script>

<style scoped>
</style>
