<template>
  <v-container fluid>
    <v-list-item three-line>
      <v-list-item-content>
        <p class="display-1 text--primary">
          {{ $t('menu.documents') }}
        </p>
      </v-list-item-content>
    </v-list-item>
    <v-data-table
      :headers="headers"
      :items="documents"
      :loading="loading"
      :options.sync="options"
      :server-items-length="totalFromServer"
      v-model="selectedItems"
      :sort-desc="true"
      :show-select="showCheckBox"
      checkbox-color="primary"
      :no-data-text="$t('general.ui.not_data_found')"
      :loading-text="$t('general.notification.loadData')"
      :footer-props="{
        ...rowsPerPageItemsOptions,
        itemsPerPageText: $t('general.table.itemsPerPageText'),
        pageText: '{0}-{1} ' + $t('general.table.pageText') + ' {2}',
      }"
    >
      <template v-slot:top>
        <v-row class="d-flex justify-space-between align-center">
          <v-col lg="4" md="4" sm="12" xl="4">
            <v-text-field
              onkeypress="return (event.charCode != 34)"
              v-on:keyup.86="replace"
              v-on:keyup.ctrl.86="replace"
              dense
              filled
              rounded
              color="primary"
              v-model="search"
              :clearable="true"
              @keypress.enter="searchForText"
              @click:clear="searchAll"
              @click:append="searchForText"
              append-icon="mdi-magnify"
              :label="$t('general.titles.filter')"
              single-line
              hide-details
            />
          </v-col>
          <v-col lg="4" md="4" sm="12" xl="4">
            <v-btn
              small
              tile
              :elevation="0"
              color="gray"
              style="border-radius: 1.5em"
              @click="checkBoxShow"
              class="mr-1"
              :disabled="!(documents && documents.length > 0)"
            >
              <span v-if="!showCheckBox">
                <v-icon small color="neuter" left
                  >mdi-checkbox-multiple-marked-outline</v-icon
                >
                <span class="neuter--text">{{
                  $t('general.buttons.checksBoxEnable')
                }}</span>
              </span>
              <span v-else>
                <v-icon small color="neuter" left
                  >mdi-checkbox-multiple-marked</v-icon
                >
                <span class="neuter--text">{{
                  $t('general.buttons.checksBoxDisable')
                }}</span>
              </span>
            </v-btn>
          </v-col>
          <v-col lg="4" md="4" sm="8" xl="4">
            <v-toolbar flat color="white">
              <v-spacer></v-spacer>
              <button-group-component :buttons="buttonsConfig">
                <template v-slot:dialog-content-2>
                  <AddOrEditComponent @update:loading="loading = $event" :edited-index="editedIndex" :profile="profile" :dialog="dialog" @dialog:action="dialog = $event" @update:index="editedIndex = $event" />
                </template>
              </button-group-component>
            </v-toolbar>
          </v-col>
        </v-row>
        <!--FILTERS TOOLBAR *********************************************************************-->
        <v-toolbar
          class="mb-3"
          outlined
          elevation="0"
          style="border-radius: 0.3em"
          v-if="filtersChips && filtersChips.length > 0"
        >
          <v-row style="display: contents">
            <v-col cols="12">
              <v-chip-group show-arrows>
                <v-chip
                  small
                  close
                  v-for="chip in filtersChips"
                  :key="chip.value"
                  @click:close="cleanFilter(chip.value)"
                  :disabled="!!(search && search !== '')"
                >
                  <v-icon left color="t-adj-primary darken-3" small>mdi-filter-variant</v-icon>
                  {{ chip.name }}
                </v-chip>
              </v-chip-group>
            </v-col>
          </v-row>
        </v-toolbar>
        <!--FILTERS TOOLBAR *********************************************************************-->
      </template>
      <template v-slot:item.expirated="{ item }">
        <td class="pa-0">
          <span class="dot-active" v-if="validateDate(item.validade)">

          </span>
          <span class="dot-expired" v-else>

          </span>
        </td>
      </template>
      <!--<template v-slot:item.authority_name="{ item }">
        <span>{{ item.authority_name }}</span>
      </template>-->
      <template v-slot:item.description_name="{ item }">
        <ReadMore
          v-if="translate(item.description).length > 20"
          :classes="'mb-0'"
          :text="translate(item.description)"
          :max-chars="20"
          :font-size="14"
        />
        <span v-else>{{ translate(item.description) }}</span>
      </template>
      <template v-slot:item.emission="{ item }">
        <span>{{ item.emission }}</span>
      </template>
      <template v-slot:item.validade="{ item }">
        <span v-if="item.validade">{{ item.validade }}</span>
        <span v-else>
          -
        </span>
      </template>
      <template v-slot:item.storage_devices="{ item }">
        <ShowMoreComponent v-if="item.storageDevices.length > 0" :item="item" secondary-field="storageDevices" />
        <span v-else>-</span>
      </template>
      <template v-slot:item.observations="{ item }">
        <span v-if="item.observations">
          <read-more
              :text="item.observations"
              :max-chars="20"
              :font-size="14"
          />
        </span>
        <span v-else>
          -
        </span>
      </template>
      <template v-slot:item.season="{ item }">
        <v-chip small v-if="item.season">
          {{ translate(item.season.languageKey) }}
        </v-chip>
        <span v-else>
          -
        </span>
      </template>
      <template v-slot:item.isPublic="{ item }">
        <span v-if="item.isPublic">
          <v-chip small>
            {{ $t('documents.labels.options.visible') }}
          </v-chip>
        </span>
        <span v-else>
          <v-chip small disabled>
            {{ $t('documents.labels.options.notVisible') }}
          </v-chip>
        </span>
      </template>
      <template v-slot:item.active="{ item }">
        <v-icon
            small
            class="mr-4"
            color="success"
            v-if="item.active"
        >
          mdi-checkbox-marked-circle-outline
        </v-icon>
        <v-icon
            small
            class="mr-4"
            color="disabled"
            v-else
        >
          mdi-checkbox-marked-circle-outline
        </v-icon>
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip bottom>
          <!-- Dialog MORE DETAILS -->
          <MoreDetailsComponent :item="item"
                                :dialog="showDocumentDialog[item.id]"
                                @close:dialog="$set(showDocumentDialog, item.id, $event)"
                                @loading:download="$set(loadingDownloadDocument, $event.id, $event.loading)"
          />
          <template v-slot:activator="{ on }">
            <v-icon
                v-on="on"
                color="neuter"
                small
                class="mr-2"
                @click="showDocument(item)"
            >
              mdi-eye-outline
            </v-icon>
          </template>
          <span>
                {{ $t('general.buttons.show') }}
                <span style="font-size: 12px; color: #aaaaaa">
                  {{ $t('menu.documents') }}
                </span>
              </span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
                v-on="on"
                color="neuter"
                small
                class="mr-2"
                @click="showVersions(item)"
            >
              mdi-file-document-outline
            </v-icon>
          </template>
          <span>
            {{ $t('general.buttons.showVersions') }}
            <span style="font-size: 12px; color: #aaaaaa">
              {{ $t('menu.documents') }}
            </span>
          </span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              v-if="check([{ domain: 'Document', permisions: ['Update'] }])"
              color="neuter"
              class="mr-2"
              small
              @click="editItem(item)"
            >
              mdi-square-edit-outline
            </v-icon>
          </template>
          <span>
            {{ $t('general.buttons.edit') }}
            <span style="font-size: 12px; color: #aaaaaa">
              {{ $t('menu.documents') }}
            </span>
          </span>
        </v-tooltip>
        <ConfirmDialog
          v-if="check([{ domain: 'Document', permisions: ['Delete'] }])"
          :item="item"
          :is-icon="true"
          :is-tooltip="true"
          :is-btn="false"
          :title="$t('general.titles.alert')"
          :dialog-text="$t('general.notification.deleteItems')"
          :tp-text="$t('general.buttons.delete')"
          :ts-text="$t('menu.documents')"
          :tooltip="true"
          btn-title=""
          btn-icon="mdi-trash-can-outline"
          color="neuter"
          action="deleteItem"
          :frBtnProps="{
            small: true,
            color: 'error',
            textColor: 't-bw-error--text',
            elevation: 0,
            text: false
          }"
        />
      </template>
    </v-data-table>
    <!-- Dialog SHOW VERSIONS -->
    <VersionsComponent
      :showVersionsDialog="showVersionsDialog"
      :versions="versions"
      @close="showVersionsDialog = false"
    />
  </v-container>
</template>

<script>
  import i18n from '@/plugins/i18n'
  import { mapActions, mapGetters } from 'vuex'
  import moment from 'moment'
  import FilterSelect from '../../components/FilterSelect'
  import ModalFilters from '../../components/ModalFilters'
  import _ from 'lodash'
  import PermisionService from '../../services/PermisionService'
  import pdf from 'vue-pdf'
  import DocumentsService from '../../services/DocumentsService'
  import ConfirmDialog from '../../components/ConfirmDialog'
  import LanguajeService from '@/services/LanguajeService'
  import sortList from '@/services/OrderListService'
  import ReadMore from '../../components/ReadMoreComponent'
  import printJs from 'print-js'
  import CompactImages from '../../services/CompactImages'
  import ShowMoreComponent from "../../components/ShowMoreComponent";
  import AddOrEditComponent from '@/module/documents/components/AddOrEditComponent'
  import MoreDetailsComponent from "@/module/documents/components/MoreDetailsComponent.vue";
  import VersionsComponent from "@/module/documents/components/VersionsComponent.vue"
  import ButtonGroupComponent from "@/components/common/ButtonGroup/ButtonGroupComponent.vue"

  const FILTER_VALUES = [
    'template_names',
    'company_id',
    'process_ids',
    'products_ids',
    'person_in_charge_ids',
    'start_record_date_time',
    'end_record_date_time',
    'structure',
    'pageableDTO',
    'record_ids',
    'min_punctuation',
    'max_punctuation',
    'surveyStateType',
    'storage_device_types_ids',
    'sdi_origin_ids',
    'sellers_date'
  ]

  const KEYS = [
    'company_id',
    'pageableDTO',
    'sdi_origin_ids',
    'document_authority_ids',
    'document_type_ids',
    'document_description_ids',
    'storage_devices_ids',
    'document_date',
    'expiration_date',
    'withExternalVisibility',
    'isActive',
    'isExpirated',
    'isPublic',
    'seasons_ids'
  ]

  export default {
    components: {
      ButtonGroupComponent,
      VersionsComponent,
      MoreDetailsComponent, AddOrEditComponent, ShowMoreComponent, ReadMore, ModalFilters, FilterSelect, pdf, ConfirmDialog },
    data: () => ({
      options: {},
      loading: false,
      loadingSaveDocument: false,
      storage_device_origin: [],
      rowsPerPageItemsOptions: {
        itemsPerPageOptions: [10, 20, 50, 100, 200],
      },
      valid: false,
      fileEditChange: false,
      fileSelected: null,
      urlImage: null,
      documentIdPreview: null,
      profile: null,
      // FILTERs
      show: false,
      fieldsFilters: [],
      refers: {
        document_date: '',
        expiration_date: '',
      },
      dialog: false,
      dialogImage: false,
      showDocumentDialog: {},
      showVersionsDialog: false,
      dialogDetails: false,
      editedIndex: -1,
      storage_device_ids: [],
      defaultItem: {},
      confirmAction: null,
      title: 'Información',
      dialogText: 'Añada un texto a este dialogo',
      deletingItem: {},
      search: '',
      loadingDownloadDocument: {},
      loadingPrintDocument: {},
      selectedItems: [],
      showCheckBox: false,
      loadingExport: false,
      complete: false,
      dialogStates: {},
      defaultPageableDTO: {
        page: 0,
        size: 10,
        sortBy: 'id',
        direction: 'desc',
      }
    }),

    computed: {
      type: {
        get() {
          return this.$store.getters['ui/GET_FILTER_TYPE']
        },
        set(val) {
          this.$store.dispatch('ui/SET_FILTER_TYPE', val)
        },
      },
      drawer: {
        get() {
          return this.$store.getters['ui/GET_DRAWER']
        },
        set(val) {
          this.$store.dispatch('ui/SET_DRAWER', val)
        },
      },
      filters: {
        get() {
          return this.$store.getters['ui/GET_FILTERS']
        },
        set(val) {
          this.$store.dispatch('ui/SET_FILTERS', val)
        },
      },
      optionsTable: {
        get() {
          return this.$store.getters['ui/GET_OPTIONS_TABLE']
        },
        set(val) {
          this.$store.dispatch('ui/SET_OPTIONS_TABLE', val)
        },
      },
      locations: {
        get() {
          return this.$store.getters['ui/GET_LOCATIONS_FILTERS']
        },
        set(val) {
          this.$store.dispatch('ui/SET_LOCATIONS_FILTERS', val)
        },
      },
      filtersObjects: {
        get() {
          return this.$store.getters['ui/GET_OBJECTS_TO_FILTERS']
        },
        set(val) {
          this.$store.dispatch('ui/SET_OBJECTS_TO_FILTERS', val)
        },
      },
      date: {
        get() {
          return this.$store.getters['ui/GET_DATE_FILTERS']
        },
        set(val) {
          this.$store.dispatch('ui/SET_DATE_FILTERS', val)
        },
      },
      filtersChips: {
        get() {
          return this.$store.getters['ui/GET_FILTERS_CHIPS']
        },
        set(val) {
          this.$store.dispatch('ui/SET_FILTERS_CHIPS', val)
        },
      },
      cantFilters: {
        get() {
          return this.$store.getters['ui/GET_COUNT_FILTERS']
        },
        set(val) {
          this.$store.dispatch('ui/SET_COUNT_FILTERS', val)
        },
      },
      documents: {
        get() {
          return this.$store.getters['documents/filterDocuments']
        },
        set(val) {
          this.$store.commit('documents/SET_FILTERS_DOCUMENTS_LIST', val)
        },
      },
      versions: {
        get() {
          return this.$store.getters['documents/versions']
        },
        set(val) {
          this.$store.commit('documents/SET_VERSIONS_LIST', val)
        },
      },
      documentSelected: {
        get() {
          return this.$store.getters['documents/document']
        },
        set(val) {
          this.$store.commit('documents/SET_DOCUMENT', val)
        },
      },
      ...mapGetters({
        totalFromServer: 'documents/filterDocumentsTotalCount',
        allStoragesByUser: 'authority/authoritiesStoragesDevices',
        // Add Documents
        //----ui-----------------------------------------
        filterHeader: 'ui/GET_HEADERS_DOCUMENTS',
      }),
      isImageOrPdf() {
        return ['jpg', 'png', 'jpeg', 'pdf'].includes(
            this.documentSelected.file_extension
        )
      },
      buttonsConfig() {
        return [
          {
            type: 'button',
            text: i18n.t('general.buttons.filter'),
            icon: 'mdi-filter-variant',
            action: this.openFilters,
            props: {
              small: true,
              elevation: 0,
              color: 'gray',
            },
            badge: {
              content: this.cantFilters,
              value: this.cantFilters,
              color: 'neuter lighten-1',
              bordered: true,
              overlap: true,
              left: true,
            },
          },
          {
            type: 'button',
            text: i18n.t('general.buttons.export'),
            icon: 'mdi-download-multiple',
            action: this.exportCSVDocuments,
            props: {
              loading: this.loadingExport,
              small: true,
              elevation: 0,
              color: 'gray',
              disabled: !(this.documents && this.documents.length > 0),
            },
          },
          {
            type: 'dialog',
            text: i18n.t('general.buttons.new'),
            icon: 'mdi-plus',
            props: {
              small: true,
              elevation: 0,
              color: 'gray',
            },
          },
        ];
      },
      headers() {
        return [
          {
            text: "",
            value: "expirated",
            sortable: false,
          },
          /*{
            text: i18n.t('documents.fields.authority'),
            value: 'authority_name',
            val: 'documentAuthority',
            type: 'text',
            width: 150,
            sortable: true,
          },*/
          {
            text: i18n.t('documents.fields.type'),
            value: 'type_name',
            type: 'text',
            width: 200,
            sortable: false,
          },
          {
            text: i18n.t('documents.fields.description'),
            value: 'description_name',
            type: 'text',
            width: 250,
            sortable: false,
          },
          {
            text: i18n.t('documents.fields.document_date'),
            value: 'emission',
            val: 'document_date',
            type: 'text',
            width: 130,
            sortable: true,
          },
          {
            text: i18n.t('documents.fields.expiration_date'),
            value: 'validade',
            val: 'expiration_date',
            type: 'text',
            width: 130,
            sortable: true,
          },
          {
            text: i18n.t('documents.fields.location'),
            value: 'storage_devices',
            type: 'list',
            width: 120,
            sortable: false,
          },
          {text: i18n.t('documents.fields.season'), val: 'seasons_ids', value: 'season', type: 'text', sortable: false},
          {
            text: i18n.t('documents.fields.observations'),
            value: 'observations',
            type: 'text',
            width: 200,
            sortable: false,
          },
          {
            text: i18n.t('documents.fields.public'),
            value: 'isPublic',
            val: 'isPublic',
            type: 'text',
            width: 120,
            sortable: true,
          },
          {
            text: i18n.t('general.fields.active'),
            value: 'active',
            type: 'text',
            width: 100,
            sortable: true,
          },
          {
            text: i18n.t('general.fields.action'),
            value: 'action',
            width: 140,
            sortable: false,
            align: 'center'
          },
        ]
      },
    },
    watch: {
      options: {
        async handler() {
          this.optionsTable = this.options
          await this.searchText()
        },
        deep: true,
      },
    },
    mounted() {
      this.type = 'documents'
      this.$store.commit('ui/FILTERS_ORIGINAL_STATE')
      let filtersDocuments = JSON.parse(
        localStorage.getItem('filtersDocuments')
      )

      if (filtersDocuments) {
        this.filters.isActive = typeof filtersDocuments.filtersObjects.document.isActive === 'boolean'
            ? filtersDocuments.filtersObjects.document.isActive
            : null
        this.filters.isExpirated = typeof filtersDocuments.filtersObjects.document.isExpirated === 'boolean'
            ? filtersDocuments.filtersObjects.document.isExpirated
            : null
        this.filters.isPublic = typeof filtersDocuments.filtersObjects.document.isPublic === 'boolean'
            ? filtersDocuments.filtersObjects.document.isPublic
            : null

        this.filters.document_type_ids =
            filtersDocuments.document_type_ids &&
            filtersDocuments.document_type_ids.length > 0
                ? filtersDocuments.document_type_ids
                : []

        this.filters.document_date =
          filtersDocuments.filtersDocumentDate['document_date'] &&
          filtersDocuments.filtersDocumentDate['document_date'].length > 0
            ? [
                new Date(
                  filtersDocuments.filtersDocumentDate['document_date'][0]
                )
                  .toISOString()
                  .slice(0, 19),
                new Date(
                  filtersDocuments.filtersDocumentDate['document_date'][1]
                )
                  .toISOString()
                  .slice(0, 19),
              ]
            : []
        this.filters.expiration_date =
          filtersDocuments.filtersDocumentDate['expiration_date'] &&
          filtersDocuments.filtersDocumentDate['expiration_date'].length > 0
            ? [
                new Date(
                  filtersDocuments.filtersDocumentDate['expiration_date'][0]
                )
                  .toISOString()
                  .slice(0, 19),
                new Date(
                  filtersDocuments.filtersDocumentDate['expiration_date'][1]
                )
                  .toISOString()
                  .slice(0, 19),
              ]
            : []
        this.date.filtersDocumentDate = filtersDocuments.filtersDocumentDate
          ? filtersDocuments.filtersDocumentDate
          : []

        this.filters.document_type_ids =
          filtersDocuments.document_type_ids &&
          filtersDocuments.document_type_ids.length > 0
            ? filtersDocuments.document_type_ids
            : []
        this.filters.document_description_ids =
          filtersDocuments.document_description_ids &&
          filtersDocuments.document_description_ids.length > 0
            ? filtersDocuments.document_description_ids
            : []
        this.filters.document_authority_ids =
          filtersDocuments.document_authority_ids &&
          filtersDocuments.document_authority_ids.length > 0
            ? filtersDocuments.document_authority_ids
            : []

        this.filters.withExternalVisibility = filtersDocuments.withExternalVisibility

        this.locations.destination_locations_filters =
          filtersDocuments.destination_locations_filters
            ? filtersDocuments.destination_locations_filters
            : this.locations.destination_locations_filters
        this.filters.sdi_destination_ids =
          filtersDocuments.filtersObjects.sdi_destination_ids &&
          filtersDocuments.filtersObjects.sdi_destination_ids.length > 0
            ? filtersDocuments.filtersObjects.sdi_destination_ids
            : null
        this.filters.storage_devices_ids =
          filtersDocuments.storage_devices_ids &&
          filtersDocuments.storage_devices_ids.length > 0
            ? filtersDocuments.storage_devices_ids
            : null

        this.filtersObjects = filtersDocuments.filtersObjects
          ? filtersDocuments.filtersObjects
          : this.filtersObjects

        this.filtersChips =
          filtersDocuments.filtersChips &&
          filtersDocuments.filtersChips.length > 0
            ? filtersDocuments.filtersChips
            : []
        this.cantFilters =
          filtersDocuments.cantFilters &&
          filtersDocuments.cantFilters.length > 0
            ? filtersDocuments.cantFilters
            : null
      }

      if (this.date.filtersDocumentDate) {
        this.dateRangeText('expiration_date')
        this.dateRangeText('document_date')
      }

      if (this.locations.destination_locations_filters || this.filtersObjects) {
        this.closeFilterDialog()
      }

      this.$root.$on('setFiltersDocuments', () => {
        this.closeFilterDialog()
      })

      this.$root.$on('getResultsPaginatedByCompany', () => {
        this.getDocumentsPaginatedByCompany()
      })

      this.$root.$on('deleteItem', (item) => {
        this.deleteItem(item)
      })
    },
    created() {
      this.$store.commit('ui/SET_FILTERS_SORT', [22, 23, 24, 25, 13, 26, 27, 28, 29, 34]);
      this.initialize()

      if (this.editedIndex !== -1) {
        this.storageData = this.allStoragesByUser
      } else {
        this.storageData = _.filter(this.allStoragesByUser, { active: true })
      }
    },
    methods: {
      ...mapActions({
        // Add Documents
        saveDocument: 'documents/saveDocument',
        deleteDocument: 'documents/deleteDocument',
        fetchListDocumentAuthority:
          'document_authority/fetchListDocumentAuthority',
        fetchStorageTypesActiveByCompany:
          'storage_type/fetchStorageTypesActiveByCompany',
        fetchListDocumentsFiltered: 'documents/fetchListDocumentsFiltered',
        fetchListDocumentsBySearch: 'documents/fetchListDocumentsBySearch',
        fetchDocumentById: 'documents/fetchDocumentById',
        fetchListVersions: 'documents/fetchListVersions',
        exportCSV: 'documents/exportCSV',
      }),
      openDialog(index) {
        this.$set(this.dialogStates, index, true);
        this.editedIndex = -1;
      },
      closeDialog(index) {
        this.$set(this.dialogStates, index, false);
      },
      validateDate(date) {
        if (date) {
          date = date.split('/')
          date = moment(`${date[2]}-${date[1]}-${date[0]}`).unix()
          const current = moment().unix()

          return date > current
        } else {
          return true
        }
      },
      async searchAll() {
        this.options.page = 1

        await this.getDocumentsPaginatedByCompany()
      },
      async searchForText() {
        this.options.page = 1
        this.$store.commit('ui/FILTERS_ORIGINAL_STATE')
        this.$store.commit('ui/CLEAR_LOCAL_STORAGE')
        await this.searchText()
      },
      async searchText() {
        this.loading = true
        const keys = ['pageableDTO']
        let filters = _.pick(this.filters, keys)

        filters.companyId = this.profile.company_id
        filters.stringFilter = this.search

        let direction =
          this.options.sortDesc.length === 0 || this.options.sortDesc[0]
            ? 'desc'
            : 'asc'
        let sort =
          this.options.sortBy.length === 0
            ? 'document_date'
            : _.filter(this.headers, { value: this.options.sortBy[0] })[0].val

        filters.pageableDTO = {
          page: this.options.page - 1,
          size: this.options.itemsPerPage,
          sortBy: sort ? sort : 'document_date',
          direction: direction,
        }

        if (this.search && this.search !== '') {
          await this.fetchListDocumentsBySearch([filters, this.$toast])
        } else {
          await this.getDocumentsPaginatedByCompany()
        }

        this.loading = false
      },
      async exportCSVDocuments() {
        this.loadingExport = true

        if (!this.filters.pageableDTO)
          this.filters.pageableDTO = this.defaultPageableDTO

        let filters = _.pick(this.filters, KEYS)
        filters.language = LanguajeService.getLenguajeName()
        filters.document_ids = this.getByProperty(this.selectedItems, 'id')

        if ((this.search && this.search !== '') && !(this.selectedItems && this.selectedItems.length > 0)) {
          filters.export_by_string = true
          filters.string_filter = {
            companyId: this.profile.company_id,
            stringFilter: this.search,
            pageableDTO: this.filters.pageableDTO
          }

          filters = _.omit(filters, ['withExternalVisibility', 'sdi_origin_ids', 'storage_devices_ids', 'company_id', 'document_authority_ids', 'document_date', 'document_description_ids', 'document_ids', 'document_type_ids', 'expiration_date'])

        } else {
          filters.export_by_string = false
          filters.string_filter = null
        }

        await this.exportCSV([filters, this.$toast]).finally(() => {
          this.loadingExport = false
          this.complete = true
          setTimeout(() => {
            this.complete = false
          }, 2000)
        })
      },
      checkBoxShow() {
        this.showCheckBox = !this.showCheckBox

        if (!this.showCheckBox) this.selectedItems = []
      },
      openFilters() {
        this.type = 'documents'

        const filtersDocuments = JSON.parse(localStorage.getItem('filtersDocuments'));

        console.log('init >>> set:location:origin', true)
        this.$root.$emit("set:location:origin", {
          locations: filtersDocuments.destination_locations_filters,
          storageDevice: filtersDocuments.filtersObjects.sdi_destination_ids
        })

        this.drawer = true
      },
      filteredChips() {
        let chips = []
        const filters = _.pick(this.filters, KEYS)

        console.log(filters)

        _.forEach(filters, (value, key) => {
          if (
            ![...FILTER_VALUES].includes(key) &&
            this.filters[key] !== null &&
            this.filters[key] !== undefined &&
            this.filters[key].length > 0
          ) {
            console.log(key)
            chips.push({
              value: key,
              name: _.filter(this.filterHeader, { val: key })[0].text,
            })
          } else {
            if (typeof this.filters.isActive === 'boolean' && key === 'isActive') {
              chips.push({
                value: key,
                name: _.filter(this.filterHeader, { val: key })[0].text,
              })
            }
            if (typeof this.filters.isExpirated === 'boolean' && key === 'isExpirated') {
              chips.push({
                value: key,
                name: _.filter(this.filterHeader, { val: key })[0].text,
              })
            }
            if (typeof this.filters.isPublic === 'boolean' && key === 'isPublic') {
              chips.push({
                value: key,
                name: _.filter(this.filterHeader, { val: key })[0].text,
              })
            }
          }
        })

        this.filtersChips = chips
      },
      getCantFilters() {
        const filters = _.pick(this.filters, KEYS)
        let cant = 0
        _.forEach(filters, (value, key) => {
          if (
            ![...FILTER_VALUES, 'expiration_date', 'document_date', 'withExternalVisibility', 'isActive', 'isExpirated', 'isPublic'].includes(
              key
            ) &&
            value !== null
          ) {
            cant += value.length
          }
          cant +=
            (key === 'expiration_date' && value && value.length > 0) ||
            (key === 'document_date' && value && value.length > 0)
              ? 1
              : 0

          cant +=
              (key === 'withExternalVisibility' && value && value.length > 0)
                  ? 1
                  : 0

          cant +=
              (key === 'isActive' && typeof value === 'boolean')
                  ? 1
                  : 0

          cant +=
              (key === 'isExpirated' && typeof value === 'boolean')
                  ? 1
                  : 0

          cant +=
              (key === 'isPublic' && typeof value === 'boolean')
                  ? 1
                  : 0
        })
        this.cantFilters = cant
      },
      initialize() {
        this.fieldsFilters.headers = _.filter(this.headers, header => {
          if (header.type !== 'actions') {
            return header
          }
        })
        this.profile = JSON.parse(localStorage.getItem('profile'))
        if (this.profile) {
          this.fetchListDocumentAuthority([this.profile, this.$toast])
          this.fetchStorageTypesActiveByCompany([
            this.$toast,
          ])
        }
      },
      async getDocumentsPaginatedByCompany() {
        this.loading = true
        let direction =
          this.options.sortDesc.length === 0 || this.options.sortDesc[0]
            ? 'desc'
            : 'asc'
        let sort =
          this.options.sortBy.length === 0
            ? 'document_date'
            : _.filter(this.headers, { value: this.options.sortBy[0] })[0].val

        this.filters.pageableDTO = {
          page: this.options.page - 1,
          size: this.options.itemsPerPage,
          sortBy: sort ? sort : 'document_date',
          direction: direction,
        }

        this.filters.company_id = this.profile.company_id
        const filters = _.pick(this.filters, KEYS)

        await this.fetchListDocumentsFiltered([filters, this.$toast]).finally(
          () => {
            this.loading = false
          }
        )
      },
      check(permisions) {
        return PermisionService.check(permisions)
      },
      translate(key, uppercase) {
        return LanguajeService.getKey3(key)
      },
      async showDocument(item) {
        this.loading = true
        await this.fetchDocumentById([item.id, this.$toast]).finally(() => {
          this.$set(this.showDocumentDialog, item.id, true);
          this.loading = false
        })
      },
      async showVersions(item) {
        this.loading = true
        await this.fetchListVersions([item.id, this.$toast]).finally(() => {
          this.showVersionsDialog = true
          this.loading = false
        })
      },
      async editItem(item) {
        this.fileEditChange = false

        await this.$root.$emit('fetch:data', item)

        // this.fileSelected = await fetch(this.documentSelected.s3_url,{ mode: 'no-cors'}).then(r => r.blob()).then(blobFile => new File([blobFile], this.documentSelected.type_name,{type: type, size:blobFile.size}));
        // this.fileSelected = await fetch(this.documentSelected.s3_url,{ mode: 'no-cors'}).then(r => r.blob()).then(blobFile => new File([blobFile], this.documentSelected.type_name,{type: type, size:blobFile.size}));
      },
      async deleteItem(item) {
        await this.deleteDocument([item.id, this.$toast]).finally(() => {
          this.fetchListDocumentsFiltered([this.filters, this.$toast])
        })
      },
      // FILTERS
      cleanFilter(tag) {
        let filtersDocuments = JSON.parse(
          localStorage.getItem('filtersDocuments')
        )

        if (tag === 'expiration_date') {
          this.date.filtersDocumentDate.expiration_date = []
          this.filters['expiration_date'] = []
          this.filtersObjects.document.expiration_date = []
          filtersDocuments.filtersDocumentDate.expiration_date = []
          filtersDocuments.filtersObjects.document.expiration_date = []
        } else if (tag === 'document_date') {
          this.date.filtersDocumentDate.document_date = []
          this.filters['document_date'] = []
          this.filtersObjects.document.document_date = []
          filtersDocuments.filtersDocumentDate.document_date = []
          filtersDocuments.filtersObjects.document.document_date = []
        } else if (tag === 'isActive') {
          this.filtersObjects.document[tag] = null
          this.filters.isActive = null
          filtersDocuments.filtersObjects.document[tag] = null
        } else if (tag === 'isExpirated') {
          this.filtersObjects.document[tag] = null
          this.filters.isExpirated = null
          filtersDocuments.filtersObjects.document[tag] = null
        } else if (tag === 'isPublic') {
          this.filtersObjects.document[tag] = null
          this.filters.isPublic = null
          filtersDocuments.filtersObjects.document[tag] = null
        } else {
          if (this.filtersObjects[tag]) {
            filtersDocuments.filtersObjects[tag] = []
            this.filtersObjects[tag] = []
          }

          this.filters[tag] = null
          filtersDocuments[tag] = null

          if (tag === 'storage_devices_ids') {
            this.locations.destination_locations_filters = []
            filtersDocuments.destination_locations_filters = []
            this.filtersObjects.sdi_destination_ids = []
            this.filters['storage_devices_ids'] = []
            this.filters['sdi_destination_ids'] = []

            this.$root.$emit("set:location:destination", {
              locations: [],
              storageDevice: []
            })
          }
        }

        localStorage.setItem(
          'filtersDocuments',
          JSON.stringify(filtersDocuments)
        )
        this.applyFilters()
        this.filteredChips()
        this.getCantFilters()
      },
      dateRangeText(field) {
        let [year, month, day] = '-'
        let [year2, month2, day2] = '-'
        if (
          this.date.filtersDocumentDate[field] &&
          this.date.filtersDocumentDate[field].length > 1
        ) {
          if (this.date.filtersDocumentDate[field][0]) {
            ;[year, month, day] =
              this.date.filtersDocumentDate[field][0].split('-')
          }
          if (this.date.filtersDocumentDate[field][1]) {
            ;[year2, month2, day2] =
              this.date.filtersDocumentDate[field][1].split('-')
          }
          this.filtersObjects.document[
            field
          ] = `${day}/${month}/${year} ~ ${day2}/${month2}/${year2}`
        } else {
          this.filtersObjects.document[field] = []
        }
      },
      closeFilterDialog() {
        this.search = ''
        this.applyFilters()
        this.filteredChips()
        this.getCantFilters()
        this.show = false
      },
      getFilterModel() {
        return {
          authority_name: [],
          type_name: [],
          description_name: [],
          document_date: [],
          expiration_date: [],
          storage_device_ids: [],
          storage_device_names: [],
          storage_device_names_list: [],
        }
      },
      applyFilters() {
        let filtersDocuments = JSON.parse(
          localStorage.getItem('filtersDocuments')
        )

        if (filtersDocuments) {
          this.filters.isActive = typeof filtersDocuments.filtersObjects.document.isActive === 'boolean'
              ? filtersDocuments.filtersObjects.document.isActive
              : null
          this.filters.isExpirated = typeof filtersDocuments.filtersObjects.document.isExpirated === 'boolean'
              ? filtersDocuments.filtersObjects.document.isExpirated
              : null
          this.filters.isPublic = typeof filtersDocuments.filtersObjects.document.isPublic === 'boolean'
              ? filtersDocuments.filtersObjects.document.isPublic
              : null

          this.filters.document_type_ids =
            filtersDocuments.document_type_ids &&
            filtersDocuments.document_type_ids.length > 0
              ? filtersDocuments.document_type_ids
              : []
          this.filters.document_description_ids =
            filtersDocuments.document_description_ids &&
            filtersDocuments.document_description_ids.length > 0
              ? filtersDocuments.document_description_ids
              : []
          this.filters.document_authority_ids =
            filtersDocuments.document_authority_ids &&
            filtersDocuments.document_authority_ids.length > 0
              ? filtersDocuments.document_authority_ids
              : []

          this.filters.withExternalVisibility = filtersDocuments.withExternalVisibility

          this.locations.destination_locations_filters =
            filtersDocuments.destination_locations_filters
              ? filtersDocuments.destination_locations_filters
              : this.locations.destination_locations_filters
          this.filters.sdi_destination_ids =
            filtersDocuments.filtersObjects.sdi_destination_ids &&
            filtersDocuments.filtersObjects.sdi_destination_ids.length > 0
              ? filtersDocuments.filtersObjects.sdi_destination_ids
              : null
          this.filters.storage_devices_ids =
            filtersDocuments.storage_devices_ids &&
            filtersDocuments.storage_devices_ids.length > 0
              ? filtersDocuments.storage_devices_ids
              : null

          this.filters.seasons_ids =
            filtersDocuments.filtersObjects.seasons_ids &&
            filtersDocuments.filtersObjects.seasons_ids.length > 0
              ? this.getByProperty(
                filtersDocuments.filtersObjects.seasons_ids,
                'id'
              )
              : []

          this.filters.document_date =
            filtersDocuments.filtersDocumentDate['document_date'] &&
            filtersDocuments.filtersDocumentDate['document_date'].length > 0
              ? [
                  new Date(
                    filtersDocuments.filtersDocumentDate['document_date'][0] +
                      'T00:00:00.000Z'
                  )
                    .toISOString()
                    .slice(0, 19),
                  new Date(
                    filtersDocuments.filtersDocumentDate['document_date'][1] +
                      'T23:59:59.000Z'
                  )
                    .toISOString()
                    .slice(0, 19),
                ]
              : null
          this.filters.expiration_date =
            filtersDocuments.filtersDocumentDate['expiration_date'] &&
            filtersDocuments.filtersDocumentDate['expiration_date'].length > 0
              ? [
                  new Date(
                    filtersDocuments.filtersDocumentDate['expiration_date'][0] +
                      'T00:00:00.000Z'
                  )
                    .toISOString()
                    .slice(0, 19),
                  new Date(
                    filtersDocuments.filtersDocumentDate['expiration_date'][1] +
                      'T23:59:59.000Z'
                  )
                    .toISOString()
                    .slice(0, 19),
                ]
              : null
        }

        this.getDocumentsPaginatedByCompany()
      },

      getByProperty(array, property) {
        return _.map(array, property)
      },
      replace(event) {
        event.currentTarget.value = event.currentTarget.value
          .toString()
          .replaceAll('"', '')
        this.search = event.currentTarget.value.toString().replaceAll('"', '')
      },
    },

    beforeDestroy() {
      this.type = null;
      console.log('Documents view destroyed');
    },

    destroyed() {
      this.$store.commit('ui/FILTERS_OBJECTS_ORIGINAL_STATE');
      this.$root.$off('setFiltersDocuments')
      this.$root.$off('getResultsPaginatedByCompany')
    },
  }
</script>

<style scoped lang="scss">
  .visibility {
    padding: 1px 8px;
    background-color: rgb(46 227 81 / 8%);
    border-radius: 21px;
    font-size: 15px;
    color: #6e6e6eba;
  }
  .not-visibility {
    padding: 1px 8px;
    background-color: rgb(227 46 46 / 8%);
    border-radius: 21px;
    font-size: 15px;
    color: #6e6e6eba;
  }
  .dot-current {
    height: 42px;
    width: 8px;
    background-color: rgb(3,172,140); // Green background for old browsers
    background-color: rgba(3,172,140,1); // Green background, with transparency for more modern browsers. Will be ignored by old browsers
    display: inline-block;
  }
  .dot-active {
    height: 42px;
    width: 8px;
    background-color: rgb(3,172,140); // Green background for old browsers
    background-color: rgba(3,172,140,1); // Green background, with transparency for more modern browsers. Will be ignored by old browsers
    display: inline-block;
  }
  .dot-expired {
    height: 42px;
    width: 8px;
    background-color: rgb(232,59,54); // Red background for old browsers
    background-color: rgba(232,59,54,1); // Red background, with transparency for more modern browsers. Will be ignored by old browsers
    display: inline-block;
  }
  :deep(.v-chip-group .v-slide-group__content) {
    display: flex !important;
    justify-content: flex-start !important;
  }
  :deep(.v-toolbar__content) {
    padding: 0 !important;
  }
</style>
